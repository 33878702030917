@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
$cui-sidebar-bg: #000000 !important;
// If you want to override variables do it here
@import 'variables';

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import '@coreui/coreui/scss/coreui';

// Import Chart.js custom tooltips styles
@import '@coreui/chartjs/scss/coreui-chartjs';

@import 'layout';
@import 'example';

// If you want to add custom CSS you can put it here.
@import 'custom';
.bg-light-purple{
  background: #311898 url(../assets/images/lginbg.webp) no-repeat fixed center bottom !important;
  background-size: cover;
}
*{
  font-family: 'Poppins', sans-serif !important;
  font-size: .92rem !important;
}
.pageName{
  font-size: 1.75rem !important;
  font-weight: 500 !important;
  color: #ffffff;
}
.header-toggler{
  color: #ffffff !important;
}
.header-toggler{
  display: inline-block !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 3.125rem;
  padding: 0.625rem;
  z-index: 999;
  border-radius: 0.938rem;
  background: var(--bs-card-bg);
}
.header{
  background: rgba(49, 24, 152, .5) !important;
  border-bottom: 0 !important;
}
.bg-light.wrapper{
  background: #311898 !important;
}
.rc-pagination {
  text-align: right;
}
.sidebar{
  background: #0e235a !important;
}
.sidebar-brand{
  background: transparent !important;
}
.header-divider{
  display: none !important;
}
.sidebar-nav .nav-link.active,
.sidebar-nav .nav-link:hover{
  background: rgb(195,46,255);
  background: linear-gradient(90deg, rgba(195,46,255,1) 0%, rgba(110,74,255,1) 100%);
  border-radius: 0.938rem;
}
.add-btn {
  color: #0881ba !important;
  border-color: #0881ba !important;
  background-color: #ffffff !important;
  box-shadow: none !important;
  float: right;
}

.add-btn:hover {
  color: #ffffff !important;
  border-color: #0881ba !important;
  background-color: #0881ba !important;
}

.submit-btn {
  background-color: #0881ba !important;
  border-color: #0881ba !important;
  box-shadow: none !important;
}

.submit-btn:hover {
  background-color: #3897c4 !important;
  border-color: #0881ba !important;
  box-shadow: none !important;
}

.back-btn {
  background-color: #0152ff !important;
  border-color: #0152ff !important;
  box-shadow: none !important;
  // margin-left: 85%;
  float: right;
}

.back-btn:hover {
  background-color: #0767f7 !important;
  border-color: #0767f7 !important;
  box-shadow: none !important;
}

.edit-btn {
  background-color: #3897c4 !important;
  border-color: #3897c4 !important;
  box-shadow: none !important;
}

.edit-btn:hover {
  background-color: #3897c4 !important;
  border-color: #0881ba !important;
  box-shadow: none !important;
}

#btn {
  margin-right: '30px';
}

.dispute_detail {
  margin-left: 10px;
}

.margin_right {
  margin-right: 10px;
}

.btn {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-hourglass:after {
  content: ' ';
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid rgb(36, 1, 1);
  border-color: rgba(11, 12, 12, 0.142) transparent #fff transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  100% {
    transform: rotate(1800deg);
  }
}

.table-responsive thead th {
  white-space: nowrap;
}
.sidebar-nav .nav-link{
  padding-left: 20px !important;
  font-size: 1rem !important;
}
.input-inline{
  display: inline-flex;
  align-items: center;
}
.field-group{
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
}
.mr-2{
  margin-right:10px;
}
.m-0{
  margin: 0 !important;
}
.react-datepicker__input-container input{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--cui-input-color, rgba(44, 56, 74, 0.95));
  background-color: var(--cui-input-bg, #fff);
  background-clip: padding-box;
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.card .header{
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.radio-group {
  padding: 7px 0;
}
.radio-choice-group{
  margin-right: 20px;
  display: inline-flex;
  align-items: center;
}
.radio-choice-group input{
  margin-top: 0;
  margin-right: 7px;
  display: inline-flex;
}
.table > :not(caption) > * > *{
  vertical-align: middle;
}
.logoPreview{
width: 200px;
height: auto;
margin-top: 15px;
border: 0.0625rem solid rgba(255, 255, 255, 0.25) !important;
border-radius: 0.5rem !important;
padding: 15px;
}
.mt-3{
  margin-top: 15px !important;
}
.nav.nav-tabs.tabContentMain {
  border-bottom: 0 !important;
  margin-left: 15px;
}
#plus_minus-button{
  display: flex;
  align-items: flex-end;
  height: 100%;
}
#plus_minus-button .btn{
  margin: 0 15px 0 0;
  min-height: 38px;
}
.radioLabelWidth{
  min-width: 100px;
}

.animatedWrapper{
  position: relative;
}
.animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.animation .circle {
  width: 689px;
  height: 689px;
  display: block;
  border-radius: 50%;
  background: linear-gradient(131.92deg, #5811CF 14.55%, rgba(49, 24, 152, 0.5) 95.62%);
  position: fixed;
}
.animation .one {
  left: 40%;
  top: -276px;
  animation: MoveX 5s ease infinite;
}
.animation .two {
  left: -211px;
  top: 105px;
  animation: MoveY 5s ease infinite;
}
.animation .three {
  left: 70%;
  top: 70%;
  animation: MoveX 6s ease infinite;
}
.animation .circle.four {
  width: 387px;
  height: 387px;
  display: block;
  border-radius: 50%;
  background: #6F1898;
  opacity: 0.8;
  position: fixed;
  right: -5%;
  top: -15%;
  filter: blur(70px);
}
.animation .line-1 {
  position: absolute;
  top: 40%;
  left: 0;
  z-index: -1;
}
.animation .line-2 {
  position: absolute;
  top: 70%;
  left: 0;
  z-index: -1;
}
// -----keyframes---
@keyframes  MoveX{
  100%, 0%{
         transform: translate(0,0);
     }
     50%{
         transform: translate(30px,-70px);
     }
}
@keyframes  MoveY{
 100%, 0%{
        transform: translateX(0);
    }
    50%{
        transform: translateX(-70px);
    }
}
.newCard{
  margin-bottom: 1.875rem;
  background-color: rgba(13,36,88, 0.6);
  transition: all .5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 0.938rem;
  box-shadow: 0 0.25rem 0.375rem rgba(62, 73, 84, 0.04);
  height: calc(100% - 1.875rem);
  padding: 1.875rem;
}
.widgetCard .newCard{
  height: auto;
  padding: 1rem;
}
.widgetCardFlex{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.widgetCardFlex h4{
  color: #ffffff;
  margin-bottom: 0 !important;
  font-size: 1.75rem !important;
}
.widgetCardFlex h4 small{
  display: block;
  font-size: 0.875rem !important;
  line-height: 1.6;
  color: #ffffff;
}
.footer{
  padding: 0.3875rem 0;
  font-size: 0.875rem;
  border-top-left-radius: 1.875rem;
  border-top-right-radius: 1.875rem;
  margin-top: 30px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  background-color: rgba(13,36,88, 0.6);
  position: relative;
  z-index: 1;
  border-top: 0 !important;
}
.footer div{
  width: 100%;
  display: block;
  text-align: center;
  color: #ffffff !important;
}
.card{
  background-color: rgba(13,36,88, 0.6) !important;
  border-radius: 0.938rem !important;
  border: 0 !important;
}
.card-body > br{
  display: none !important;
}
.table{
  border: 0 !important;
}
thead, tbody, tfoot, tr, td, th{
  color: #ffffff !important;
  border: 0 !important;
}
.table th{
  background-color: transparent !important;
  font-weight: 500 !important;
}

.card-header{
  color: #ffffff !important;
  font-weight: 600;
  font-size: 1.125rem !important;
  line-height: 1.5;
  border-bottom: 0 !important;
  padding: 15px 20px 0 !important;
}
.px-0{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.btn-primary{
  background: linear-gradient(90deg, rgb(195, 46, 255) 0%, rgb(110, 74, 255) 100%) !important;
  box-shadow: none !important;
  border: 0 !important;
  color: #ffffff !important;
  font-size: 1rem !important;
}
.btn-big{
  background: linear-gradient(90deg, rgb(195, 46, 255) 0%, rgb(110, 74, 255) 100%) !important;
  box-shadow: none !important;
  border: 0 !important;
  color: #ffffff !important;
  font-size: 1.25rem !important;
  padding: 15px 30px !important;
  display: block;
  width: 100%;
  margin: 0 !important;
  transition: .5s;
}
.btn-big:hover{
  background: linear-gradient(90deg, rgb(110, 74, 255) 0%, rgb(195, 46, 255) 100%) !important;
}
.sidebar-nav .nav-link{
  color: #ffffff !important;
  font-weight: 500 !important;
}
a .icon{
  color: #ffffff !important;
}
.input-group-text{
  background: linear-gradient(90deg, rgb(195, 46, 255) 0%, rgb(110, 74, 255) 100%) !important;
  border: 0 !important;
  text-align: center !important;
  display: block !important;
}
.btn-secondary{
  background-color: #52C8FB !important;
  color: #ffffff !important;
  box-shadow: none !important;
  border: 0 !important;
  font-size: 1rem !important;
}
.btn-outline-secondary{
  border-color: #52C8FB !important;
  color: #ffffff !important;
  box-shadow: none !important;
}
.btn-outline-secondary:hover,
.btn-outline-secondary.active{
  border-color: #52C8FB !important;
  background-color: #52C8FB !important;
  color: #ffffff !important;
}
.btn-group .dropdown-menu{
  background-color: #52C8FB !important;
  color: #ffffff !important;
}
.btn-group .dropdown-menu a{
  color: #ffffff !important;
}
.btn-group .dropdown-menu a:hover{
  background-color: #41b6e8 !important;
}
.nav-link{
  padding: 10px 10px !important;
  margin: 10px 10px !important;
}
.nav-group-items .nav-link{
  margin-top: 5px;
  margin-bottom: 5px;
}
.field-group label{
  color: #ffffff !important;
}
.rc-pagination-item-link{
  color: #59CDFF !important;
}
.rc-pagination-prev .rc-pagination-item-link, .rc-pagination-next .rc-pagination-item-link{
  background-color: rgba(89, 205, 255, 0.1) !important;
  border-color: rgba(81, 203, 255, 0.1) !important;
  color: #59CDFF !important;
  border-radius: 0.5rem !important;
  padding: .15rem 1rem .25rem !important;
  height: auto !important;
  font-size: 30px !important;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.rc-pagination-prev, .rc-pagination-next, .rc-pagination-jump-prev, .rc-pagination-jump-next{
  height: auto !important;
}
.rc-pagination-total-text{
  color: #fff;
}
.rc-pagination-item{
  height: auto !important;
  padding: .15rem 1rem .25rem !important;
  background-color: rgba(89, 205, 255, 0.1) !important;
  border-color: rgba(81, 203, 255, 0.1) !important;
  border-radius: 0.5rem !important;
  min-width: none !important;
  font-weight: 700 !important;
  color: #59CDFF !important;
}
td, th{
  white-space: nowrap !important;
}
.form-control{
  background: rgba(255, 255, 255, 0.03) !important;
  border: 0.0625rem solid rgba(255, 255, 255, 0.25) !important;
  border-radius: 0.5rem !important;
  padding: 0.3125rem 1.25rem !important;
  color: #fff !important;
  height: 3.5rem !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  display: block !important;
  width: 100% !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  appearance: none !important;
  box-shadow: none !important;
}
.select__control{
  background: rgba(255, 255, 255, 0.03) !important;
  border: 0.0625rem solid rgba(255, 255, 255, 0.25) !important;
  border-radius: 0.5rem !important;
  padding: 0.3125rem 1.25rem !important;
  color: #fff !important;
  height: 3.5rem !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  appearance: none !important;
  box-shadow: none !important;
}
.select__value-container{
  padding-left: 0 !important;
}
.select__multi-value{
  background: rgba(255, 255, 255, 0.03) !important;
  border: 0.0625rem solid rgba(255, 255, 255, 0.25) !important;
  border-radius: 0.25rem !important;
}
.select__multi-value__label{
  color: #fff !important;
}
.select__dropdown-indicator{
  opacity: .5 !important;
}
.select__indicator-separator{
  opacity: .5 !important;
}
.select__menu{
  background: rgba(255, 255, 255, 0.03) !important;
  border: 0.0625rem solid rgba(255, 255, 255, 0.25) !important;
  border-radius: 0.25rem !important;
}
.select__menu > div > div:hover,
.select__menu > div > div:focus{
  background: #52c8fb !important;
}
.select__menu > div{
  color: #ffffff !important;
}
.select__input-container{
  color: #ffffff !important;
}
.react-datepicker__input-container input[type=text]{
  background: rgba(255, 255, 255, 0.03) !important;
  border: 0.0625rem solid rgba(255, 255, 255, 0.25) !important;
  border-radius: 0.5rem !important;
  padding: 0.3125rem 1.25rem !important;
  color: #fff !important;
  height: 3.5rem !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  display: block !important;
  width: 100% !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  appearance: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.react-datepicker, .react-datepicker__header{
  background: rgba(11, 28, 76, 0.9) !important;
  border: 0 !important;
  border-radius: 0.5rem !important;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: rgba(11, 28, 76, 0.9) !important;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: rgba(11, 28, 76, 0.9) !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name,
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  color: #ffffff !important;
}
.react-datepicker__input-container input[type=text]:focus{
  background: rgba(255, 255, 255, 0.03) !important;
  border: 0.0625rem solid rgba(255, 255, 255, 0.25) !important;
  border-radius: 0.5rem !important;
  padding: 0.3125rem 1.25rem !important;
  color: #fff !important;
  height: 3.5rem !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  display: block !important;
  width: 100% !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  appearance: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background: linear-gradient(90deg, rgb(195, 46, 255) 0%, rgb(110, 74, 255) 100%) !important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
  background: linear-gradient(90deg, rgb(195, 46, 255) 0%, rgb(110, 74, 255) 100%) !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
  background: linear-gradient(90deg, rgb(195, 46, 255) 0%, rgb(110, 74, 255) 100%) !important;
}
.form-select{
  background: rgba(255, 255, 255, 0.03) !important;
  border: 0.0625rem solid rgba(255, 255, 255, 0.25) !important;
  border-radius: 0.5rem !important;
  padding: 0.3125rem 1.25rem !important;
  color: #fff !important;
  height: 3.5rem !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  display: block !important;
  width: 100% !important;
  box-shadow: none !important;
}
.col-form-label{
  color: #ffffff !important;
}
.nav.nav-tabs.tabContentMain{
  position: relative;
}
.nav-tabs .nav-link {
  display: block !important;
  padding: 0.65rem 1rem !important;
  color: #53CAFD !important;
  text-decoration: none !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !important;
  border-radius: 0.938rem 0.938rem 0 0 !important;
  border-color: transparent !important;
  margin: 0 !important;
  font-size: 1rem !important;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background: #0e235a !important;
  border-color: #311899 #31179b #2e1499 !important;
  color: white !important;
}
.form-check-label{
  color: #ffffff !important;
}
.card-body h4{
  color: #ffffff;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}
.form-label{
  color: #ffffff !important;
}
.form-check-input:checked {
  background-color: var(--cui-form-check-input-checked-bg-color, #53cafd);
  border-color: var(--cui-form-check-input-checked-border-color, #53cafd);
}
.header-nav .dropdown-menu{
    border: 0 !important;
    border-radius: 0.938rem !important;
    box-shadow: 0 0 3.125rem 0 rgba(82, 63, 105, 0.15) !important;
    margin-top: 0;
    background: #0e235a !important;
    min-width: 9rem !important;
    z-index: 99;
    padding-top: 10px !important;
}
.header-nav .dropdown-menu .dropdown-item {
  font-size: 1rem !important;
  color: #ffffff !important;
  padding: 0.5rem 1.75rem !important;
  text-decoration: none !important;
  border-radius: 0.938rem !important;
}
.header-nav .dropdown-menu .dropdown-item:hover,
.header-nav .dropdown-menu .dropdown-item:focus,
.header-nav .dropdown-menu .dropdown-item:active,
.header-nav .dropdown-menu .dropdown-item.active {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  
}
.crediantial-title{
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  color: #ffffff;
  text-align: center;
  margin-bottom: 24px !important;
}
.login-brand{
  text-align: center;
  margin-bottom: 30px;
}
.addFieldGroup{
  display: flex;
  align-items: center;
}
.addFieldGroup label{
  margin-bottom: 0 !important;
}
.addFieldGroup .form-control{
  margin-left: 15px;
}
.tabFlex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.margin-right-10{
  margin-right: 10px !important;
}
a{
  color: #53CAFD !important;
  text-decoration: none !important;
}
select option {
  background: #282676;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  background: red;
  cursor: inherit;
  display: block;
}
.file-input-label {
	padding: 0px 10px;
	display: table-cell;
	vertical-align: middle;
  border-radius: 4px;
}
input[readonly] {
  background-color: white !important;
  cursor: text !important;
}
.btn-file-group{
  background: rgba(255, 255, 255, 0.03) !important;
  border: 0.0625rem solid rgba(255, 255, 255, 0.25) !important;
  border-radius: 0.5rem !important;
  color: #fff !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  appearance: none !important;
  box-shadow: none !important;
}
.newCard .card-header{
  padding-top: 0 !important;
}
.dashNotify{
  margin: 15px 0 0 0;
  padding: 0;
}
.dashNotify li{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 20px;
}
.dashNotify li span{
  display: block;
  align-items: center;
  justify-content: center;
  font-size: 16px !important;
  color: #ffffff !important;
}
.dashNotifyIcon{
  background: linear-gradient(90deg, rgb(195, 46, 255) 0%, rgb(110, 74, 255) 100%);
  padding: 9px 10px 5px 11px;
  border-radius: .5rem;
  margin-right: 15px;
}
.dashNotifyIcon .icon {
  width: 20px !important;
  height: 20px !important;
}
html:not([dir="rtl"]) .dashNotifyIcon .icon.me-2{
  margin-right: 0 !important;
}
